import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RegistrationForm = ({ data }) => {
  const [selectedRadio, setSelectedRadio] = useState("junior")

  const handleRadioClick = e => {
    setSelectedRadio(e.target.value)
  }

  return (
    <Layout>
      <SEO title="Registration" />
      <div id="main">
        <div style={{ backgroundColor: "#FFF" }}>
          <a href="/">
            <img
              id="logo"
              src={data.file.publicURL}
              alt="Enosh Bible Camp Logo"
            />
          </a>
        </div>
        <div className="diagonal">
          <svg viewBox="0 0 100 10" preserveAspectRatio="none">
            <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
          </svg>
          <Link className="button--anchor" to="/">
            Return To Home
          </Link>
        </div>
        <div className="info">
          <form>
            <span>Select which form to fill out</span>
            <div className="radio-inputs">
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="campweek"
                  value="junior"
                  onChange={handleRadioClick}
                  checked={selectedRadio === "junior"}
                />
                <span className="radio-tile">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span className="radio-label">Junior High Camper Form</span>
                    <span style={{ fontSize: ".75em" }}>July 8 - 12</span>
                    <span style={{ fontSize: ".75em" }}>9:00 AM - 4:00 PM</span>
                  </div>
                </span>
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="campweek"
                  value="grade"
                  onChange={handleRadioClick}
                  checked={selectedRadio === "grade"}
                />
                <span className="radio-tile">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span className="radio-label">
                      Grade School Camper Form
                    </span>
                    <span style={{ fontSize: ".75em" }}>July 15 - 19</span>
                    <span style={{ fontSize: ".75em" }}>9:00 AM - 4:00 PM</span>
                  </div>
                </span>
              </label>
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="campweek"
                  value="vbs"
                  onChange={handleRadioClick}
                  checked={selectedRadio === "vbs"}
                />
                <span className="radio-tile">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <span className="radio-label">
                      Topeka Gospel Chapel VBS Form
                    </span>
                    <span style={{ fontSize: ".75em" }}>May 28 - 31</span>
                    <span style={{ fontSize: ".75em" }}>
                      9:00 AM - 11:30 AM
                    </span>
                  </div>
                </span>
              </label>
            </div>
          </form>
          <iframe
            id="junior"
            title="Enosh Bible Camp Junior High Registration Form"
            style={{ display: selectedRadio === "junior" ? "block" : "none" }}
            src="https://docs.google.com/forms/d/e/1FAIpQLScVqhPRp7Dd8DPmw0HKl75Uaj4a6S8XFxsRYDiVMa5LXSJWqw/viewform?embedded=true"
            width="100%"
            height="3600"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            onLoad={() => window.parent.scrollTo(0, 0)}
          >
            Loading...
          </iframe>
          <iframe
            id="grade"
            title="Enosh Bible Camp Grade School Registration Form"
            style={{ display: selectedRadio === "grade" ? "block" : "none" }}
            src="https://docs.google.com/forms/d/e/1FAIpQLSevROWt8SfyYpjcPIh5w7Wamr1x1_tr2ZmIakYYsPICqWre9g/viewform?embedded=true"
            width="100%"
            height="3600"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            onLoad={() => window.parent.scrollTo(0, 0)}
          >
            Loading...
          </iframe>
          <iframe
            id="vbs"
            title="Topeka Gospel Chapel VBS Registration Form"
            style={{ display: selectedRadio === "vbs" ? "block" : "none" }}
            src="https://docs.google.com/forms/d/e/1FAIpQLSdtNn1uEVX-rDOToTk42sjpdPucI8360F0jsm0n7tqMFMXcsA/viewform?embedded=true"
            width="100%"
            height="1400"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            onLoad={() => window.parent.scrollTo(0, 0)}
          >
            Loading...
          </iframe>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default RegistrationForm
